import React, { useEffect } from "react"
import { Layout } from "../../common/Layout"
import { SEO } from "../../common/SEO"
import { Subtitle, Title, Wrapper } from "./PageErreur.style"
import { navigate } from "gatsby"

interface Props {
  className?: string
}

export function PageErreur400View() {
  useEffect(() => {
    setTimeout(() => navigate("/"), 5000)
  }, [])

  return (
    <Layout>
      <Wrapper>
        <SEO
          title="400: Bad Request"
          description="Une erreur est apparue lors du chargement de la page."
          isCookieYesLoaded={false}
        />
        <Title>Oups, un problème est survenu...</Title>
        <Subtitle>
          Nous allons vous diriger vers la page d'accueil dans quelques
          secondes.
        </Subtitle>
      </Wrapper>
    </Layout>
  )
}
